<template>
  <b-container class="ticketAssist">
    <b-row>
      <b-col class="mainTitle">
        <b-img
          class="businessplatformlogo"
          :src="logo"
        />
        <span class="card-logo aiops_white_heading3">AI TICKET ASSIST</span>
        <!-- <b-img
          class="businessplatformlogo"
          src="@/assets/images/logo/logo.png"
        />AI TICKET ASSSIST -->
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        class="title"
      >
        SUGGESTED CLASSIFICATION
      </b-col>
      <b-col class="resultRows">
        <slot name="suggested-classification" />
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col
        cols="12"
        class="title"
      >
        SIMILAR TICKETS
      </b-col>
      <b-col class="resultRows">
        <slot name="similar-tickets" />
      </b-col>
    </b-row> -->
  </b-container>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BContainer,
  BIcon,
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BContainer,
    BIcon,
    BImg,
  },
  props: {
    logo: {
      type: String,
      // eslint-disable-next-line global-require
      default: require('@/assets/images/logo/logo.png'),
    },
    ticketAssistData: {
      type: Object,
      default() {
        return {
          sugClassifications: ['Software > Security', 'Urgent'],
          similarTickets: [
            {
              idRef: '#',
              ticketId: 'IT3221',
              ticketDescription: 'It think i received a scam e-mail yesterday from a sender I don\'t recognize my stuff over the night',
            },
            {
              idRef: '#',
              ticketId: 'IT2217',
              ticketDescription: 'It think i received a scam e-mail yesterday from a sender I don\'t recognize my stuff over the night',
            },
            {
              idRef: '#',
              ticketId: 'IT2783',
              ticketDescription: 'It think i received a scam e-mail yesterday from a sender I don\'t recognize my stuff over the night',
            },
          ],
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.ticketAssist {
  color: #9abac2;
  padding: 4rem;
  border-radius: 1vh;
  display: flex;
  flex-direction: column;
}

.ticketAssist * {
  display: flex;
  flex-direction: column;
}

.ticketAssist .resultRows * {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
}

.ticketAssist .resultRows > * {
  background-color: #fff;
}

.ticketAssist .mainTitle,
.ticketAssist .title,
.ticketAssist .resultRows > * {
  outline: 1px solid #cecece9d;
}

.ticketAssist .mainTitle {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #00aa9b;
  color: #fff;
  font-size: 1.2rem;
  font-weight: light;
  text-align: center;
  padding: 0.8rem;
}

.ticketAssist .title {
  background-color: #ddedee;
  color: #3e7282;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  text-align: center;
  padding: 0.5rem;
}

.ticketCode {
  color: #00aa9b;
  padding-left: 1rem;
}

.sugTicketDescription{
  width: 100%;
  white-space: nowrap;
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
}

.businessplatformlogo {
  position: relative;
  background-color: transparent;
  padding: 10px;
  height: 50px;
  width: 60px;
  vertical-align: middle;
}

.col .card-logo {
  position: relative;
  padding-top: 3px;
  display: inline-block;
}

</style>
