<template>
  <b-container class="aiops-categorization-component">
    <b-row>
      <slot name="header-categorization" />
    </b-row>
    <b-row>
      <slot name="subheader-categorization" />
    </b-row>
    <b-row>
      <b-col class="resultRows">
        <slot name="suggested-categorization" />
      </b-col>
    </b-row>
    <b-row>
      <slot name="header-similar-tickets" />
    </b-row>
    <b-row>
      <b-col class="resultRows">
        <slot name="similar-tickets" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BContainer,
  BIcon,
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BContainer,
    BIcon,
    BImg,
  },
  props: {
    logo: {
      type: String,
      // eslint-disable-next-line global-require
      default: require('@/assets/images/logo/logo.png'),
    },
    ticketAssistData: {
      type: Object,
      default() {
        return {
          sugClassifications: ['Software > Security', 'Urgent'],
          similarTickets: [
            {
              idRef: '#',
              ticketId: 'IT3221',
              ticketDescription: 'It think i received a scam e-mail yesterday from a sender I don\'t recognize my stuff over the night',
            },
            {
              idRef: '#',
              ticketId: 'IT2217',
              ticketDescription: 'It think i received a scam e-mail yesterday from a sender I don\'t recognize my stuff over the night',
            },
            {
              idRef: '#',
              ticketId: 'IT2783',
              ticketDescription: 'It think i received a scam e-mail yesterday from a sender I don\'t recognize my stuff over the night',
            },
          ],
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.aiops-categorization-component {
  color: #9abac2;
}

.aiops-categorization-component .resultRows * {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
}

.aiops-categorization-component .resultRows > * {
  background-color: #ddedee;
}

.aiops-categorization-component .mainTitle,
.aiops-categorization-component .title,
.aiops-categorization-component .resultRows > * {
  outline: 1px solid #f2f8f8;
}

.aiops-categorization-component .mainTitle {
  justify-content: center;
  background-color: #00aa9b;
  border-radius: 5px 5px 0px 0px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  color: #fff;
  font-size: 1.2rem;
  font-weight: light;
  text-align: center;
}

.aiops-categorization-component .title {
  background-color: #eef7f9;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  text-align: center;
}

.aiops-categorization-component .titleAI {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #5dd1c6;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  text-align: center;
}

.aiops-categorization-component .mainTitleSimilarTickets {
  justify-content: center;
  background-color: #00aa9b;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  color: #fff;
  text-align: center;
}

.sugTicketDescription{
  width: 100%;
  white-space: nowrap;
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .businessplatformlogo {
//   position: relative;
//   background-color: transparent;
//   padding-right: 12px;
//   height: 20px;
//   width: 32px;
//   vertical-align: middle;
// }

.col .card-logo {
  position: relative;
  padding-top: 3px;
  display: inline-block;
}

</style>
