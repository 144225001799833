<template>
  <div class="card-container">
    <b-card
      header-tag="header"
      footer-tag="footer"
      class="rounded-0 mb-0"
      no-body
    >
      <b-card-header header-class="header-container">
        <b-row class="details">
          <b-col class="d-flex headers-columns">
            <span class="aiops_neutral_blue_heading4">
              Ticket Identifier
            </span>
            <span class="ml-1 aiops_paragraph_6f9ca8">
              {{ data.ticketSourceNumber }}
            </span>
          </b-col>
          <b-col class="d-flex headers-columns">
            <span class="aiops_neutral_blue_heading4">
              Created
            </span>
            <span class="ml-1 aiops_paragraph_6f9ca8">
              {{ data.createdDate }}
            </span>
          </b-col>
          <b-col class="d-flex">
            <div class="h2 mb-0 paperclip-icon-container">
              <feather-icon
                v-if="data.attachedDoc"
                class="paperclip-icon"
                icon="PaperclipIcon"
                size="1.0x"
              />
            </div>
            <b-avatar
              v-if="data.attachedDoc"
              class="badge-class"
              variant="primary"
            >{{ data.attachedDoc }}
            </b-avatar>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <slot name="similar-ticket-card-body" />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardBody, BIcon, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BIcon,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          ticketDescription: "It think I received a scam email yesterday from a sender I don't recognize. The email pretends to be from ignus , the Telco company but the logo isn't quite right. It contains an attached file but I haven't open it. I have attached a copy of the email for your analysis. Thank you.",
          solutionDescription: "Whe have confirmed it is a scam. Please do not open the attached file by any means. We're sending you the list of actions to perform for suspicious emails and added that recipient to our email filter.",
          ticketSourceNumber: 'INC0010048',
          ticketCreationDate: '03/10/2109 12:05:21',
          solverName: 'John James',
          automatedSolution: 'Add sender to spam list',
          attachedDoc: 2, // Not displayed if 0
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>

.card-container {
  font-family: "Open Sans", Helvetica, Arial, serif;
  border: 1px solid #a7ccce;
}

.header-container {
    background-color: #eef7f9;
    border-radius: 0 !important;
    display: block !important;
    & .details {
      display: flex;
      justify-content: start;
      align-items: center;
      padding-left: 21px;
      padding-right: 21px;
    }
}

.headers-columns {
  align-items: center;
  font-size: 1.3rem;

  & .header-title {
  color:var(--bismark);
      font-weight: 550;
  }

  & .header-description {
  color: var(--gothic);
      font-weight: 500;
  }
}

.card-body-title {
    color: var(--persian-green);
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 3rem !important;
}

.card-body-description {
  line-height: 1.8em;
  color: var(--orient);
  margin-top: 1rem !important;
}

.link-container {
    display: flex;
    justify-content: center;
}
.link {
    color: var(--persian-green);
    text-decoration: underline;
    margin-top: 1rem;
}
.paperclip-icon-container {
    margin-left: auto;
    margin-right: 0;
}
.paperclip-icon {
    color: var(--persian-green);
}
.badge-class {
    background-color: var(--persian-green);
    font-size: 1rem;
    margin-left: 1.5%;
}
</style>
