<template>
  <div
    class="mt-2 pb-2 col-12"
    align-h="center"
  >
    <h2
      v-if="!verifyTicketEmpty"
      class="aiops_mineral_green_heading2 margin-top-64"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        size="20"
        class="mr-50 aips-detail-arrow-left"
        @click="checkOperatorHome"
      />
      Ticket {{ this.$route.params.id }}
    </h2>
    <div class="mainContainer margin-top-24 padding-33">
      <ticket-categorization>
        <template #header-categorization>
          <b-col class="mainTitle">
            <span class="card-logo aiops_label_white_14">CATEGORIZATION</span>
          </b-col>
        </template>
        <template #subheader-categorization>
          <b-col
            class="title"
            :class="canPredict"
          >
            <span class="card-logo aiops_white_small_3e7282">INITIAL</span>
          </b-col>
          <b-col
            v-if="ticket.canPredict"
            class="col-md-6 titleAI"
          >
            <b-img
              class="businessplatformlogo"
              :src="iconTEIS"
            />
            <span class="card-logo aiops_white_small">AI SUGGESTED</span>
          </b-col>
        </template>
        <template #suggested-categorization>
          <b-row
            align-h="end"
          >
            <b-col
              class="aiops-suggested-classification-border"
              :class="canPredict"
            >
              <b-row
                v-for="(item1, i) in ticket.ticketAssistData.classification"
                :key="i"
                align-h="end"
              >
                <b-col
                  cols="12"
                  class="text-center"
                >
                  <span
                    class="aiops_paragraph_6f9ca8 text-center"
                  >
                    {{ item1 }}
                  </span>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              v-if="ticket.canPredict"
              cols="6"
            >
              <b-row
                v-for="(item, index) in ticket.ticketAssistData.sugClassifications"
                :key="index"
                align-h="end"
              >
                <b-col
                  cols="6"
                  class="text-center pl-2"
                >
                  <span
                    class="aiops_paragraph_6f9ca8 text-center"
                  >
                    {{ item }}
                  </span>
                  <span
                    v-if="ticket.bestResolutionGruop"
                    class="aiops_paragraph_6f9ca8 text-center"
                  >
                    {{ ticket.bestResolutionGruop }}
                  </span>
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  <b-button
                    v-if="ticket.bottomAccepted"
                    class="ibop-mineral-green-button-mid"
                    @click="applyCategorisation"
                  >
                    <b-spinner
                      v-if="loadCategorization"
                      small
                    />
                    <span class="aiops_label_white_14">Accept</span>
                  </b-button>
                  <!-- <b-button
                    v-if="ticket.teisStatus === 'AUTOMATIC_CATEGORY_CHANGE'"
                    disabled
                    class="ibop-mineral-green-button-mid mintui_cursor_not_allowed"
                  >
                    <span class="aiops_label_white_14">Accepted</span>
                  </b-button> -->
                  <b-button
                    class="ibop-white-button-mid"
                    @click="userFeedback"
                  >
                    <span class="align-middle aiops_label_14_3e7282">Choose another</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </ticket-categorization>
      <b-col
        md="12"
        class="padding-top-24"
      >
        <b-row class="padding-left-4 padding-right-4">
          <b-col
            class="description-ticket pl-0"
          >
            <p>
              <span class="aiops_mineral_green_heading3">
                Area
              </span>
              <span class="aiops_paragraph_6f9ca8">
                {{ ticket.area | filterNullValue }}
              </span>
            </p>
            <!-- EDP -->
            <h3 class="aiops_mineral_green_heading3">
              Subcategory
            </h3>
            <p class="aiops_paragraph_6f9ca8">
              {{ ticket.categoryLevel2 | filterNullValue }}
            </p>
            <h3 class="aiops_mineral_green_heading3">
              Assigned to
            </h3>
            <p class="aiops_paragraph_6f9ca8">
              {{ ticket.assignedTo | filterNullValue }}
            </p>
            <h3 class="aiops_mineral_green_heading3">
              Main Issue Description
            </h3>
            <p class="aiops_paragraph_6f9ca8">
              {{ ticket.summary | filterNullValue }}
            </p>
            <h3 class="aiops_mineral_green_heading3">
              Issue Description Complement
            </h3>
            <p class="aiops_paragraph_6f9ca8">
              {{ ticket.description | filterNullValue }}
            </p>
            <h3 class="aiops_mineral_green_heading3">
              Knowledge Base
            </h3>
            <p class="aiops_paragraph_6f9ca8">
              {{ ticket.knowledge | filterNullValue }}
            </p>
            <p>
              <span class="aiops_mineral_green_heading3">Teis Status </span>
              <b-img
                v-if="ticket.teisStatus"
                :class="ticket.teisStatus | activityIcon"
                class="text-center"
              />
              <span class="aiops_paragraph_6f9ca8">
                {{ ticket.teisStatus | filterNullValue | filterDeleteUnderScore }}.
              </span>
            </p>
            <p>
              <span class="aiops_mineral_green_heading3">Client </span>
              <span class="aiops_paragraph_6f9ca8">
                {{ ticket.reportedBy | filterNullValue }}.
              </span>
            </p>
            <p>
              <span class="aiops_mineral_green_heading3">Created </span>
              <span class="aiops_paragraph_6f9ca8">
                {{ ticket.createdDate | filterNullValue }}.
              </span>
            </p>
            <p>
              <span class="aiops_mineral_green_heading3">Updated </span>
              <span class="aiops_paragraph_6f9ca8">
                {{ ticket.modifiedDate | filterNullValue }}.
              </span>
            </p>
          </b-col>
        </b-row>
        <b-row
          class="margin-bottom-24 margin-top-12"
        >
          <b-col
            class="col-md-2"
          >
            <h3
              class="aiops_mineral_green_heading2"
            >
              Ticket activity
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-table
            id="tableActivities"
            ref="table-activities"
            refreshed
            responsive
            selectable
            noCollapse
            no-border-collapse
            show-empty
            hover
            :items="ticket.activities"
            thead-class="colorHead text-center"
            tbody-class="colorBody text-center"
            @row-clicked="onRowSelected"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
                <strong>Loading...</strong>
              </div>
            </template>
            <template
              #head()="data"
            >
              <span
                v-show="data.field.key !== 'ticketActivityParams'"
                class="colorHead"
              >
                {{ data.label }}
              </span>
              <b-img
                v-if="data.field.key === 'ticketActivityType'"
                id="teis-status-info"
                class="mintui_cursor_pointer mintui_info"
              />
              <b-tooltip
                v-if="data.field.key === 'ticketActivityType'"
                target="teis-status-info"
                triggers="hover"
                placement="right"
                variant="light"
                customClass="teis-info-width"
                no-fade
              >
                <b-row>
                  <b-col>
                    <p
                      v-for="(activity, index) in activityType"
                      :key="index"
                      class="text-justify"
                    >
                      <b-img
                        :class="activity.icon"
                        class="pr-1"
                      />
                      <span class="pl-1 aiops_paragraph_6f9ca8">{{ activity.name | filterDeleteUnderScore }}</span>
                    </p>
                  </b-col>
                </b-row>
              </b-tooltip>
            </template>
            <template
              #cell()="data"
            >
              <span
                v-show="data.field.key !== 'ticketActivityParams' && data.field.key !== 'ticketActivityType'"
                class="colorBody"
              >
                {{ data.value }}
              </span>
              <b-img
                v-if="data.field.key === 'ticketActivityType'"
                :class="data.value | activityIcon"
                class="text-center"
              />
            </template>
            <template
              slot="row-details"
              slot-scope="data"
            >
              <b-table
                striped
                responsive
                :items="data.item.ticketActivityParams"
                class="mb-2"
              >
                <template #head()="dataActivityParam">
                  <span class="colorHead">{{ dataActivityParam.label.toUpperCase() }}</span>
                </template>
                <template #cell()="dataActivityParam">
                  <i>{{ dataActivityParam.value | filterNullValue }}</i>
                </template>
              </b-table>
            </template>
          </b-table>
          <!-- <b-row
            class="pl-2"
          >
            <b-col>
              <span
                v-for="(activity, index) in activityType"
                :key="index"
                class="pl-1"
              >
                <span class="pr-1">{{ activity.name }}</span>
                <b-img
                  :class="activity.icon"
                />
              </span>
            </b-col>
          </b-row> -->
        </b-row>
      </b-col>
    </div>
    <b-row
      class="pt-1 pl-1 pr-1 margin-top-48"
    >
      <b-col
        cols="12"
        class="mainTitle-ticket-assist"
      >
        <h2
          class="aiops_white_heading2"
        >
          AI TICKET ASSIST
        </h2>
      </b-col>
    </b-row>
    <b-row
      class="pt-1 pl-1 pr-1"
    >
      <b-col
        cols="12"
        class="subTitle-ticket-assist margin-bottom-24"
      >
        <h3
          class="aiops_white_heading3"
        >
          SIMILAR TICKETS
        </h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="col-12"
      >
        <h2
          v-if="ticketsSimilar.length == 0"
          class="aiops_neutral_blue_heading2 text-center margin-bottom-64 margin-top-38"
        >
          There are no similar tickets for the ticket Id {{ this.$route.params.id }}
        </h2>
      </b-col>
    </b-row>
    <b-row
      v-for="(similarTicket, index) in getSimilarTicketsCheck"
      :key="index"
      class="pt-1 pl-1 pr-1"
    >
      <similar-ticket-card
        v-if="ticketsSimilar.length !== 0"
        :data="similarTicket"
        class="margin-bottom-24 col-12 pl-0 pr-0"
      >
        <template #similar-ticket-card-body>
          <div
            class="ml-2 mr-2 margin-top-38"
          >
            <b-collapse
              :id="'collapse-' + index"
              class="mt-2"
            >
              <b-row>
                <b-col>
                  <h2
                    class="aiops_mineral_green_heading2 text-center col-md-12"
                  >
                    Similarity analysis
                  </h2>
                </b-col>
              </b-row>
              <b-row
                v-if="similarTicket.currentTicketChartOn"
                class="margin-top-24"
              >
                <b-col
                  class="col-md-6"
                >
                  <h4
                    class="aiops_neutral_blue_heading4 text-center"
                  >
                    {{ similarTicket.ticketSourceNumber }}
                  </h4>
                  <vue-apex-charts
                    type="radar"
                    :height="400"
                    :options="similarTicket.similarTicketChart.chartOptions"
                    :series="similarTicket.similarTicketChart.series"
                  />
                </b-col>
                <b-col
                  v-if="similarTicket.currentTicketChartOn"
                  class="col-md-6"
                >
                  <h4
                    class="aiops_neutral_blue_heading4 text-center"
                  >
                    {{ ticket.ticketSourceNumber }}
                  </h4>
                  <vue-apex-charts
                    type="radar"
                    height="400"
                    :options="similarTicket.currentTicketChart.chartOptions"
                    :series="similarTicket.currentTicketChart.series"
                  />
                </b-col>
              </b-row>
            </b-collapse>
            <h4 class="aiops_label_14">
              Main Issue Description
            </h4>
            <p
              class="aiops_neutral_blue_label_form_bold"
            >
              {{ similarTicket.description }}
            </p>
            <h4 class="aiops_label_14 margin-top-38">
              Solution
            </h4>
            <p
              class="aiops_label_14_3e7282"
            >
              {{ similarTicket.resolution }}
            </p>
            <div
              class="mt-1 aiops_label_14_5dd1c6"
            >
              The "{{ similarTicket.assignedTo }}" automated solution was used :
            </div>
            <b-button
              class="ibop-gothic-button mt-1"
            >
              <b-img
                class="mintui_icons8_quick_mode_on"
              />
              <span class="align-middle aiops_label_14">Use Script on your Ticket</span>
            </b-button>
            <div class="link-container">
              <b-link
                v-b-toggle="'collapse-' + index"
                class="aiops_mineral_green_label_form"
                style="text-decoration: underline;"
                @click="getTicketsVectors(similarTicket.idTicket, similarTicket.consulted)"
              >
                {{ similarTicket.collapseText }}
              </b-link>
            </div>
          </div>
        </template>
      </similar-ticket-card>
    </b-row>
    <modal-aiops-component
      title="CATEGORIZATION CORRECTION"
      :logo="logo"
    >
      <template v-slot:body>
        <validation-observer
          ref="modelFeedbackDetail"
        >
          <b-form>
            <b-row class="py-2 px-2">
              <b-col
                md="12"
              >
                <b-row>
                  <b-col md="12">
                    <b-row class="pt-1">
                      <b-col md="12">
                        <p class="text-center">
                          <span
                            class="aiops_paragraph_6f9ca8"
                          >
                            Please help us correctly classify and reassign this ticket.
                          </span>
                          <br>
                          <span
                            class="aiops_paragraph_6f9ca8"
                          >
                            Our AI model learns from these corrections and improves over time.
                          </span>
                        </p>
                      </b-col>
                      <b-col
                        md="12"
                        class="margin-top-34"
                      >
                        <b-form-group
                          class="aiops_label_form_bold"
                        >
                          <label
                            for="area"
                            class="aiops_label_14_3e7282_bold"
                          >
                            Area
                          </label>
                          <validation-provider
                            #default="{ errors }"
                            name="area"
                            rules="required"
                          >
                            <b-form-select
                              id="area"
                              v-model="areaFeedback"
                              :options="feedBackSelectArea"
                              @change="getFeedbackCategoryL2"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          class="aiops_label_form_bold"
                        >
                          <label
                            for="subcategory"
                            class="aiops_label_14_3e7282_bold"
                          >
                            Sub-category
                          </label>
                          <validation-provider
                            #default="{ errors }"
                            name="subcategory"
                            rules="required"
                          >
                            <b-form-select
                              id="subcategory"
                              v-model="subcatFeedback"
                              :options="feedBackSelectCatL2"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          v-if="optionsGroup.length > 0"
                          class="aiops_label_form_bold"
                        >
                          <label
                            for="group"
                            class="aiops_label_14_3e7282_bold"
                          >
                            Reassignation group
                          </label>
                          <validation-provider
                            #default="{ errors }"
                            name="group"
                            rules="required"
                          >
                            <b-form-select
                              id="area"
                              v-model="bestGroup"
                              :options="optionsGroup"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row
                      align-h="end"
                      class="margin-top-34"
                    >
                      <b-col
                        class="col-md-12 text-right"
                      >
                        <b-button
                          class="ibop-mineral-green-button"
                          @click="applyReject"
                        >
                          <b-img
                            class="mintui_arrow_right_circle_light"
                          />
                          <span class="align-middle aiops_label_white_14">Submit</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </modal-aiops-component>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BLink,
  BCollapse,
  VBToggle,
  VBModal,
  BImg,
  BSpinner,
  BFormInput,
  BFormSelect,
  BFormGroup,
  BForm,
  BTable,
  BTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import utilsAiops from '@/@core/utils/aiops/utils'
import {
  activityType,
  activityIcon,
} from '@/@core/utils/aiops/filterFields'
import useJwt from '@/auth/aiops/useJwt'
import store from '@/store'
import VueApexCharts from 'vue-apexcharts'
import currentTicketCard from '../../../../card/card-product/current-ticket/cardFrame.vue'
import ticketAssist from '../../../../card/card-product/ticket-assist/ticketAssist.vue'
import ticketCategorization from '../../../../card/card-product/ticket-categorization/ticketCategorization.vue'
import SimilarTickets from './SimilarTickets.vue'
import SimilarTicketCard from '../../../../card/card-product/similar-ticket/SimilarTicketCard.vue'
import BoxClassification from './TicketBoxClassification.vue'
import ModalAiopsComponent from '../../../../components/modal/modal-product/ModalAiopsComponent.vue'

const {
  getRadarChart,
  formatDate,
  getSubcategoriesL2,
  loadingProccessSpinner,
  loading,
} = utilsAiops()

const iconTEIS = require('@/assets/images/aiops/logo/TicketEnrichment_Icon_White.svg')

export default {
  components: {
    VueApexCharts,
    BButton,
    BCollapse,
    BCard,
    BRow,
    BLink,
    BCol,
    BImg,
    BSpinner,
    currentTicketCard,
    ticketAssist,
    ticketCategorization,
    BoxClassification,
    SimilarTickets,
    SimilarTicketCard,
    ModalAiopsComponent,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BForm,
    BTable,
    BTooltip,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  filters: {
    filterNullValue(val) {
      const newVal = val || '---'
      return newVal
    },
    activityIcon(val) {
      return activityIcon[val]
    },
    filterDeleteUnderScore(val) {
      const newVal = val.replaceAll('_', ' ')
      return newVal
    },
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      logo: require('@/assets/images/aiops/logo/business_platform_logo_mini.svg'),
      iconTEIS,
      llamada: [],
      ticketsSimilar: [],
      ticket: {},
      items: [],
      ticketAssistData: {},
      loadCategorization: false,
      feedBackSelectArea: [],
      feedBackSelectCatL2: [],
      optionsGroup: [],
      areaFeedback: null,
      subcatFeedback: null,
      bestGroup: null,
      required,
      activityType,
      categoryTree: [],
    }
  },
  computed: {
    verifyTicketEmpty() {
      return this.verifyTicket()
    },
    getSimilarTicketsCheck() {
      return this.ticketsSimilar
    },
    canPredict() {
      return this.ticket.canPredict === false ? 'col-md-12' : 'col-md-6'
    },
  },
  beforeCreate() {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
      appLoading.style.display = 'block'
    }
  },
  created() {
    this.asignDataTickets()
    this.verifyTicket()
    this.welcomeAssist()
  },
  methods: {
    onRowSelected(items) {
      this.selected = items

      // eslint-disable-next-line no-underscore-dangle
      if (this.selected._showDetails) {
        // eslint-disable-next-line dot-notation
        this.selected['_showDetails'] = false
      } else {
        this.$set(this.selected, '_showDetails', true)
      }
    },
    asignDataTickets() {
      let currentTicketDetail = {}
      const { ticketsDetail } = this.$route.params
      if (ticketsDetail) {
        sessionStorage.setItem('currentTicket', JSON.stringify(ticketsDetail))
      }
      if (!ticketsDetail) {
        currentTicketDetail = JSON.parse(sessionStorage.getItem('currentTicket'))
      }
      this.ticket = ticketsDetail || currentTicketDetail
      // this.ticketAssistData.classification = [this.ticket.categories]
      this.categoryTree = JSON.parse(sessionStorage.getItem('categoryTree'))
      const selectCategory = this.categoryTree.map(cat => ({ value: cat.value, text: cat.label }))
      const categorySelector = [
        { value: null, text: 'Select Category' },
        ...selectCategory,
      ]
      this.feedBackSelectArea = categorySelector
      this.optionsGroup = this.ticket.optionsGroup
      this.getSimilarTickets()
    },
    applyCategorisation() {
      loadingProccessSpinner(true)
      let applyData = {}
      if (this.ticket.catSuggested) {
        applyData = {
          idTicket: this.ticket.idTicket,
          area: this.ticket.areaSuggested,
          categoryLevel2: this.ticket.catSuggested,
          accuracyArea: this.ticket.accuracySuggest.toString(),
          accuracyCategoryLevel2: this.ticket.accuracyCatSuggest.toString(),
        }
      } else {
        applyData = {
          idTicket: this.ticket.idTicket,
          area: this.ticket.areaSuggested,
          accuracyArea: this.ticket.accuracySuggest.toString(),
        }
      }
      if (this.ticket.bestResolutionGruop) {
        applyData.reassignmentGroup = this.ticket.bestResolutionGruop
      }
      this.loadCategorization = true
      useJwt.applyArea(applyData)
        .then(() => {
          const variant = 'success'
          const mngApply = this.ticket.catSuggested ? `The area has changed to ${this.ticket.areaSuggested} with an accuracy of ${parseFloat(this.ticket.accuracySuggest).toFixed(2)}% and the subcategory has changed to ${this.ticket.catSuggested} with an accuracy of ${parseFloat(this.ticket.accuracyCatSuggest).toFixed(2)}%` : `The area has changed to ${this.ticket.areaSuggested} with an accuracy of ${parseFloat(this.ticket.accuracySuggest).toFixed(2)}%.`
          this.$bvToast.toast(mngApply, {
            title: 'New categorization successfully applied',
            variant,
            solid: true,
          })
          return this.getDetailTicket(this.ticket.idTicket)
        })
        .catch(() => {
          this.loadCategorization = false
          this.errorMessage()
          loadingProccessSpinner(false)
        })
    },
    getDetailTicket(idTicket) {
      return useJwt.getTicketDetail({ id: idTicket })
        .then(response => {
          const { data } = response
          const ticketDetail = data
          this.ticket.activities = ticketDetail.activities
          this.ticket.activities = this.ticket.activities.reverse()
          this.ticket.activities.forEach(activity => {
            const newActivity = activity
            const arrActivityParams = []
            arrActivityParams.push(newActivity.ticketActivityParams)
            newActivity.ticketActivityParams = arrActivityParams
            return newActivity
          })
          this.ticket.teisStatus = ticketDetail.teisStatus
          this.ticket.area = this.ticket.areaSuggested
          this.ticket.originalArea = this.ticket.areaSuggested
          this.ticket.categoryLevel2 = this.ticket.catSuggested
          this.ticket.originalCategoryLevel2 = this.ticket.catSuggested
          this.ticket.assignedTo = this.ticket.bestResolutionGruop
          this.loadCategorization = false
          sessionStorage.setItem('currentTicket', JSON.stringify(this.ticket))
          loadingProccessSpinner(false)
        })
        .catch(() => {
          this.ticket.area = this.ticket.areaSuggested
          this.ticket.originalArea = this.ticket.areaSuggested
          this.ticket.categoryLevel2 = this.ticket.catSuggested
          this.ticket.originalCategoryLevel2 = this.ticket.catSuggested
          this.ticket.assignedTo = this.ticket.bestResolutionGruop
          this.loadCategorization = false
          this.errorMessage()
          loadingProccessSpinner(false)
        })
    },
    userFeedback() {
      this.setDataModalFeedback()
      this.$bvModal.show('modal-aiops')
    },
    getFeedbackCategoryL2() {
      this.feedBackSelectCatL2 = getSubcategoriesL2(this.categoryTree, this.areaFeedback)
      this.subcatFeedback = null
    },
    setDataModalFeedback() {
      const {
        idTicket,
        area,
        categoryLevel2,
        areaSuggested,
        catSuggested,
        assignedTo,
        bestResolutionGruop,
      } = this.ticket
      this.idTicketFeedback = idTicket
      this.feedBackSelectCatL2 = getSubcategoriesL2(this.categoryTree, areaSuggested)
      this.areaFeedback = areaSuggested || null
      this.subcatFeedback = catSuggested || null
      this.currentArea = area || null
      this.currentCategoryLevel2 = categoryLevel2 || null
      this.currentResolutionGroup = assignedTo || null
      this.bestGroup = bestResolutionGruop || null
    },
    exitModal() {
      this.$bvModal.hide('modal-aiops')
    },
    applyReject() {
      this.$refs.modelFeedbackDetail.validate().then(success => {
        if (success) {
          if (this.areaFeedback === this.currentArea && this.subcatFeedback === this.currentCategoryLevel2 && this.bestGroup === this.currentResolutionGroup) {
            const variant = 'warning'
            const mngApply = 'The selected values are equal to the current ones'
            this.$bvToast.toast(mngApply, {
              title: 'Review the fields',
              variant,
              solid: true,
            })
            return
          }
          loadingProccessSpinner(true)
          this.exitModal()
          this.loadCategorization = true
          let applyData = {}
          applyData = {
            idTicket: this.ticket.idTicket,
            area: this.areaFeedback,
            categoryLevel2: this.subcatFeedback,
            reassignmentGroup: this.bestGroup,
          }
          useJwt.applyArea(applyData)
            .then(() => {
              const variant = 'success'
              const mngApply = 'The ticket was correctly classify'
              this.$bvToast.toast(mngApply, {
                title: 'New categorization successfully applied',
                variant,
                solid: true,
              })
              return this.getDetailTicket(this.ticket.idTicket)
            })
            .catch(error => {
              this.loadCategorization = false
              this.$refs.modelFeedbackDetail.setErrors(error)
              this.errorMessage('We are unable to apply the suggested feedback at this time.')
              this.exitModal()
              loadingProccessSpinner(false)
            })
        } else {
          this.errorMessage('All fields are mandatory')
        }
      })
    },
    getSimilarTickets() {
      const numberSimilars = 2
      const { idTicket } = this.ticket
      useJwt.getSimilarTickets({ idTicket, numberSimilars })
        .then(response => {
          const { data } = response
          this.ticketsSimilar = data
          this.ticketsSimilar.forEach((ticket, index) => {
            const item = ticket
            item.similarTicketChartOn = false
            item.currentTicketChartOn = false
            this.ticketsSimilar[index].similarTicketChartOn = false
            this.ticketsSimilar[index].currentTicketChartOn = false
            this.ticketsSimilar[index].similarTicketChart = {}
            this.ticketsSimilar[index].currentTicketChart = {}
            this.ticketsSimilar[index].collapseText = 'Show more'
            this.ticketsSimilar[index].consulted = false
            this.ticketsSimilar[index].createdDate = formatDate(this.ticketsSimilar[index].createdDate)
            this.ticketsSimilar[index].resolution = `Se sigue el procedimiento indicado en el artículo de conocimiento KB0000146${ticket.idTicket}`
          })
          loading(false)
        })
        .catch(() => {
          this.errorMessage()
          loading(false)
        })
        // JUAN DAVID PARRA4305
    },
    getTicketsVectors(_ticketId, consulted) {
      if (!consulted) {
        const similarTicketVector = this.getVectors(_ticketId)
        const currentTicketVector = this.getVectors(this.ticket.idTicket)
        Promise.all([similarTicketVector, currentTicketVector]).then(response => {
          const ticketsVectors = response
          this.ticketsSimilar.forEach((similarTicket, index) => {
            const { description } = similarTicket
            ticketsVectors.forEach(ticketVector => {
              if (similarTicket.idTicket === ticketVector.idTicket && ticketVector.idTicket === _ticketId) {
                this.ticketsSimilar[index].similarTicketChart = getRadarChart(similarTicket.ticketSourceNumber, ticketVector.embeddings)
                this.ticketsSimilar[index].similarTicketChartOn = true
                this.ticketsSimilar[index].consulted = true
                this.ticketsSimilar[index].opened = true
                this.ticketsSimilar[index].description = `${description} `
                this.ticketsSimilar[index].collapseText = 'Show less'
              }
              if (similarTicket.idTicket === _ticketId && ticketVector.idTicket === this.ticket.idTicket) {
                this.ticketsSimilar[index].currentTicketChart = getRadarChart(this.ticket.ticketSourceNumber, ticketVector.embeddings)
                this.ticketsSimilar[index].currentTicketChartOn = true
              }
            })
          })
        }).catch(error => console.log(error))
      } else {
        this.ticketsSimilar.forEach((similarTicket, index) => {
          const { description } = similarTicket
          if (similarTicket.idTicket === _ticketId && this.ticketsSimilar[index].opened) {
            this.ticketsSimilar[index].opened = false
            this.ticketsSimilar[index].description = `${description} `
            this.ticketsSimilar[index].collapseText = 'Show more'
            return
          }
          if (similarTicket.idTicket === _ticketId && !this.ticketsSimilar[index].opened) {
            this.ticketsSimilar[index].opened = true
            this.ticketsSimilar[index].description = `${description} `
            this.ticketsSimilar[index].collapseText = 'Show less'
          }
        })
      }
      return this.ticketsSimilar
    },
    getVectors(ticketId) {
      return new Promise((resolve, reject) => {
        useJwt.getTicketVectors(ticketId)
          .then(response => {
            const { data } = response
            resolve(data)
          })
          .catch(error => {
            this.errorMessage()
            loading(false)
            reject(error)
          })
      })
    },
    verifyTicket() {
      if (this.ticket && Object.entries(this.ticket).length !== 0) {
        return false
      }
      this.checkOperatorHome()
      return true
    },
    checkOperatorHome() {
      this.$router.push({ name: 'aiops-home' })
    },
    errorMessage(message = 'Something was wrong') {
      const variant = 'warning'
      this.$bvToast.toast(message, {
        title: 'Classification correction',
        variant,
        solid: true,
      })
    },
    welcomeAssist() {
      store.dispatch('productAiops/showWelcomeAssist', false)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .colorHead {
    background-color: #f3f2f7;
    color: #005573;
    font-size: 0.77vw;
    font-family: "Open Sans", Helvetica, Arial, serif;
  }

  .colorBody {
    background-color: #fff;
    color: #6f9ca8;
    font-size: 0.77vw;
    font-family: "Open Sans", Helvetica, Arial, serif;
    overflow-y: scroll !important;
    padding: 0.72rem 5px !important;
  }

}
.aiops_table_title {
  color: rgba(0, 170, 155, 1);
  font-family: "Open Sans", Helvetica, Arial, serif;
  font-style: normal;
  font-weight: 200;
  word-wrap: break-word;
}

.mainTitle-ticket-assist {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: #00aa9b;
  height: 56px;
}

.subTitle-ticket-assist {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: #5dd1c6;
  height: 35px;
}

.aips-detail-arrow-left {
  cursor: pointer;
}

.aiops_mineral_green_label_form:hover {
  color: var(--mineral-green);
}

.mainContainer {
  background-color: #f9fcfc;
  border: #e2efef solid 1px;
  font-family: "Open Sans", Helvetica, Arial, serif;
  border-radius: 1vh;
  overflow: hidden;
}
</style>
