<template>
  <b-container class="ticketAssist pl-4">
    <b-row>
      <b-col
        class="col-sm-12 p-2"
        :class="canPredictClass"
      >
        <b-row>
          <b-col
            cols="12"
            class="title"
          >
            <span>
              ORIGINAL CLASSIFICATION
            </span>
          </b-col>
          <b-col class="resultRows">
            <slot name="classification" />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="canPredict"
        class="col-sm-12 col-md-6 p-2"
      >
        <b-row>
          <b-col
            cols="12"
            class="mainTitle"
          >
            <b-img
              class="businessplatformlogo"
              :src="iconTEIS"
            />
            <span>AI CLASSIFICATION</span>
          </b-col>
          <b-col class="resultRows">
            <slot name="suggested-classification" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BContainer,
  BIcon,
  BImg,
} from 'bootstrap-vue'

const iconTEIS = require('@/assets/images/aiops/logo/TicketEnrichment_Icon_White.svg')

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BContainer,
    BIcon,
    BImg,
  },
  props: {
    ticketAssistData: {
      type: Object,
      default() {
        return {
          sugClassifications: ['Software > Security', 'Urgent'],
          similarTickets: [
            {
              idRef: '#',
              ticketId: 'PE-000767695',
              ticketDescription: 'It think i received a scam e-mail yesterday from a sender I don\'t recognize my stuff over the night',
            },
            {
              idRef: '#',
              ticketId: 'IT2217',
              ticketDescription: 'It think i received a scam e-mail yesterday from a sender I don\'t recognize my stuff over the night',
            },
            {
              idRef: '#',
              ticketId: 'IT2783',
              ticketDescription: 'It think i received a scam e-mail yesterday from a sender I don\'t recognize my stuff over the night',
            },
          ],
        }
      },
    },
    canPredict: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconTEIS,
    }
  },
  computed: {
    canPredictClass() {
      return this.canPredict ? 'col-md-6' : 'col-md-12'
    },
  },
}
</script>

<style lang="scss" scoped>
.ticketAssist {
  color: #9abac2;
  border-radius: 1vh;
  display: flex;
  flex-direction: column;
}

.ticketAssist .resultRows * {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
}

.ticketAssist .resultRows > * {
  background-color: #fff;
}

.ticketAssist .mainTitle,
.ticketAssist .title,
.ticketAssist .resultRows > * {
  outline: 1px solid #cecece9d;
}

.ticketAssist .mainTitle {
  flex-direction: row;
  align-items: center;
  background-color: #00aa9b;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 0.8rem;
}

.ticketAssist .title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 73px;
  background-color: #ddedee;
  color: #4e7f8d;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  padding: 0.8rem;
}

.ticketCode {
  color: #00aa9b;
  padding-left: 1rem;
}

.sugTicketDescription{
  width: 100%;
  white-space: nowrap;
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
}

.businessplatformlogo {
  position: relative;
  background-color: transparent;
  padding: 10px;
  height: 50px;
  width: 60px;
  vertical-align: middle;
}

</style>
