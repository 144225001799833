export const filterFields = [
  { value: null, text: '-- choose field --' },
  { value: 'ticketSourceNumber', text: 'Ticket ID' },
  { value: 'teisStatus', text: 'Teis Status' },
  { value: 'area', text: 'Area' },
  // { value: 'categoryLevel2', text: 'Categorization Level 2' },
  { value: 'assignedTo', text: 'Assigned to' },
  { value: 'createdDate', text: 'Create date' },
  { value: 'modifiedDate', text: 'Modify date' },
  { value: 'summary', text: 'Summary' },
  { value: 'description', text: 'Description' },
]

export const filterConditions = [
  { value: null, text: '-- oper --' },
  { value: '=', text: 'Is' },
  { value: '!=', text: 'Is not' },
  { value: '=NULL', text: 'Is empty' },
  { value: '!=NULL', text: 'Is not empty' },
  { value: '_LIKE_', text: 'Contains' },
]

export const filterConditionsDate = [
  { value: null, text: '-- oper --' },
  { value: '>', text: 'Greater than' },
  { value: '<', text: 'Less than' },
  { value: '>=', text: 'Greater than or equal to' },
  { value: '<=', text: 'Less than or equal to' },
]

export const filterConditionsStrings = [
  { value: null, text: '-- oper --' },
  { value: '_LIKE_', text: 'Contains' },
]

export const filtersValues = [
  { value: 'ticketSourceNumber', text: 'Ticket ID' },
  { value: 'area', text: 'Area' },
  { value: 'teisStatus', text: 'Teis Status' },
  // { value: 'categoryLevel2', text: 'Categorization Level 2' },
  { value: 'assignedTo', text: 'Assigned to' },
  { value: 'createdDate', text: 'Create date' },
  { value: 'modifiedDate', text: 'Modify date' },
  { value: 'summary', text: 'Summary' },
  { value: 'description', text: 'Description' },
  { value: '=', text: 'Is' },
  { value: '!=', text: 'Is not' },
  { value: '=NULL', text: 'Is empty' },
  { value: '!=NULL', text: 'Is not empty' },
  { value: '>', text: 'Greater than' },
  { value: '<', text: 'Less than' },
  { value: '>=', text: 'Greater than or equal to' },
  { value: '<=', text: 'Less than or equal to' },
  { value: '_LIKE_', text: 'Contains' },
]

export const rowsPerPage = [
  { value: '10', text: '10' },
  { value: '25', text: '25' },
  { value: '50', text: '50' },
  { value: '75', text: '75' },
  { value: '100', text: '100' },
]

export const fields = [
  {
    key: 'ticketSourceNumber',
    label: 'Ticket ID',
    sortable: false,
    sort: {
      asc: 'asc',
      desc: 'desc',
    },
  },
  {
    key: 'summary',
    label: 'Main Issue Description',
    sortable: false,
    sort: {
      asc: 'asc',
      desc: 'desc',
    },
  },
  {
    key: 'teisStatus',
    label: 'Teis Status',
    sortable: false,
    class: 'text-center',
    sort: {
      asc: 'asc',
      desc: 'desc',
    },
  },
  {
    key: 'status',
    label: 'Status',
    sortable: false,
    sort: {
      asc: 'asc',
      desc: 'desc',
    },
  },
  {
    key: 'priority',
    label: 'Priority',
    sortable: false,
    sort: {
      asc: 'asc',
      desc: 'desc',
    },
  },
  {
    key: 'createdDate',
    label: 'Created',
    sortable: false,
    sort: {
      asc: 'asc',
      desc: 'desc',
    },
  },
  {
    key: 'modifiedDate',
    label: 'Modified',
    sortable: false,
    sort: {
      asc: 'asc',
      desc: 'desc',
    },
  },
]

export const activityColor = {
  INITIAL_STATE: 'info',
  MANUAL_CATEGORY_CHANGE: 'success',
  AUTOMATIC_CATEGORY_CHANGE: 'primary',
  ITSM_CATEGORY_CHANGE: 'secondary',
}

export const activityIcon = {
  INITIAL_STATE: 'mintui_Ellipse_10',
  INITIAL_TEIS_STATE: 'mintui_icons8_ok_green',
  MANUAL_CATEGORY_CHANGE: 'mintui_edit_user',
  AUTOMATIC_CATEGORY_CHANGE: 'mintui_TicketEnrichment_Icon_Neutral_blue_15',
  ITSM_CATEGORY_CHANGE: 'mintui_icons8_e-learning',
}

export const activityType = [
  {
    name: 'INITIAL_STATE',
    icon: 'mintui_Ellipse_10',
  },
  {
    name: 'INITIAL_TEIS_STATE',
    icon: 'mintui_icons8_ok_green',
  },
  {
    name: 'MANUAL_CATEGORY_CHANGE',
    icon: 'mintui_edit_user',
  },
  {
    name: 'AUTOMATIC_CATEGORY_CHANGE',
    icon: 'mintui_TicketEnrichment_Icon_Neutral_blue_15',
  },
  {
    name: 'ITSM_CATEGORY_CHANGE',
    icon: 'mintui_icons8_e-learning',
  },
]

export const fieldsTeisStatus = [
  { value: null, text: 'Select value' },
  { value: 'INITIAL_STATE', text: 'INITIAL STATE' },
  { value: 'MANUAL_CATEGORY_CHANGE', text: 'MANUAL CATEGORY CHANGE' },
  { value: 'INITIAL_TEIS_STATE', text: 'INITIAL TEIS STATE' },
  { value: 'AUTOMATIC_CATEGORY_CHANGE', text: 'AUTOMATIC CATEGORY CHANGE' },
  { value: 'ITSM_CATEGORY_CHANGE', text: 'ITSM CATEGORY CHANGE' },
]
