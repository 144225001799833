<template>
  <div class="card-container">
    <b-card
      header-tag="header"
      footer-tag="footer"
      class="rounded-0"
      no-body
    >
      <b-card-header header-class="header-container">
        <b-row class="details">
          <b-col class="d-flex headers-columns">
            <span class="header-title">
              Ticket Identifier
            </span>
            <span class="ml-1 header-description">
              {{ data.ticketIdentifier }}
            </span>
          </b-col>
          <b-col class="d-flex headers-columns">
            <span class="header-title">
              Created
            </span>
            <span class="ml-1 header-description">
              {{ data.ticketCreationDate }}
            </span>
          </b-col>
          <b-col class="d-flex headers-columns">
            <span class="header-title">
              Solved by
            </span>
            <span class="ml-1 header-description">
              {{ data.solverName }}
            </span>
          </b-col>
          <b-col class="d-flex">
            <div class="h2 mb-0 paperclip-icon-container">
              <feather-icon
                v-if="data.attachedDoc"
                class="paperclip-icon"
                icon="PaperclipIcon"
                size="1.0x"
              />
            </div>
            <b-avatar
              v-if="data.attachedDoc"
              class="badge-class"
              variant="primary"
            >{{ data.attachedDoc }}
            </b-avatar>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <slot name="similar-ticket-card-body" />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardBody, BIcon, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BIcon,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          ticketDescription: 'Buenos dias Es necesario habilitar el rol de lector de seguridad de O365 a X y el rol de administrador de cumplimiento a Y Por otro lado necesitaria conocer quien valido peticion usuario phonesystem y estaba solicitado el rol de Operador de seguridad. Un saludo De: y <> Enviado el: miercoles, 25 de agosto de 2021 9:56 Para: MM<>Asunto: roles office 365 Hola A, Sabes por que este usuario figura como operador de seguridad de office 365? Me refiero a phonesystem abajo tienes un recorte? Revisalo, por favor, pero me extra?a que tenga que tener este rol de operador de seguridad Por otro lado, puedes dar el rol de lector de seguridad de office 365 a IS y Encina? Hoy en dia lo tenemos S, y yo.Una ultima peticion:? Me puedes dar el rol de lector de administrador de cumplimiento a mi, por favor? Quiero ver ciertos datos de DLP, logaudit?y espero que dicho rol me lo deje ver.',
          solutionDescription: "Whe have confirmed it is a scam. Please do not open the attached file by any means. We're sending you the list of actions to perform for suspicious emails and added that recipient to our email filter.",
          ticketIdentifier: 'PE-000767695',
          ticketCreationDate: '03/11/2121 12:05:21',
          solverName: 'JJ',
          automatedSolution: 'Add sender to spam list',
          attachedDoc: 2, // Not displayed if 0
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>

.card-container {
  font-family: "Open Sans", Helvetica, Arial, serif;
  border: 2px solid var(--opal);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.header-container {
    background-color: var(--catskill-white);
    border-radius: 0 !important;
    display: block !important;
    & .details {
      display: flex;
      justify-content: start;
      align-items: center;
    }
}

.headers-columns {
  align-items: center;
  font-size: 1.3rem;

  & .header-title {
  color:var(--bismark);
      font-weight: 550;
  }

  & .header-description {
  color: var(--gothic);
      font-weight: 500;
  }
}

.card-body-title {
    color: var(--persian-green);
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 3rem !important;
}

.card-body-description {
  line-height: 1.8em;
  color: var(--orient);
  margin-top: 1rem !important;
}

.link-container {
    display: flex;
    justify-content: center;
}
.link {
    color: var(--persian-green);
    text-decoration: underline;
    margin-top: 1rem;
}
.paperclip-icon-container {
    margin-left: auto;
    margin-right: 0;
}
.paperclip-icon {
    color: var(--persian-green);
}
.badge-class {
    background-color: var(--persian-green);
    font-size: 1rem;
    margin-left: 1.5%;
}
</style>
