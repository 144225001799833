<template>
  <div class="mainContainer">
    <b-row class="details">
      <b-col
        sm="6"
        md="3"
        class="pl-3"
      >
        <span class="aiops_neutral_blue_heading4 padding-right-12">Ticket Identifier</span>
        <span class="aiops_paragraph_6f9ca8">{{ data.ticketSourceNumber }}</span>
      </b-col>
      <b-col
        sm="6"
        md="3"
      >
        <span class="aiops_neutral_blue_heading4 padding-right-12">Created</span>
        <span class="aiops_paragraph_6f9ca8">{{ data.createdDate }}</span>
      </b-col>
      <b-col
        sm="6"
        md="4"
      >
        <span class="aiops_neutral_blue_heading4 padding-right-12">Assigned to</span>
        <span class="aiops_paragraph_6f9ca8">{{ data.assignedTo }}</span>
      </b-col>
      <b-col
        sm="6"
        md="2"
        class="text-right pr-4"
      >
        <slot name="card-button" />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="no-padding">
        <div class="contentCardFrame">
          <b-row class="breadcrumb_urgency">
            <slot name="subheader-card" />
          </b-row>
          <b-row>
            <slot name="body-product-card" />
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BContainer,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BContainer,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          ticketSourceNumber: 'INC0010048',
          createdDate: '23/03/2021 15:24:52',
          assignedDate: '23/03/2021 16:11:18',
          category: 'Software > Application Crashes',
          urgency: 'Medium urgency',
          mainDescription: 'I received a suspicios e-mail. Could you please have a look at it. I\'m afraid it might contain a virus. Is it ok to open the attachment it comes with?',
          complementDescription: 'The email contains some details that make it seem like targeted phishing. I have attached 2 screenshots from the e-mail for your analysis.',
          clientId: '#124722',
          clientName: 'John Mortimor',
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.mainContainer {
  background-color: #f9fcfc;
  border: #e2efef solid 1px;
  font-family: "Open Sans", Helvetica, Arial, serif;
  border-radius: 1vh;
  overflow: hidden;
}

.details {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background-color: #ddedee;
  color: #7fa7b2;
  font-weight: 300;
}

.details .title {
  color: #095b77;
  font-weight: 700;
  font-size: 1.2rem;
}

.contentCardFrame {
  min-height: 30vh;
  padding: 0;
}

.no-padding {
  padding: 0;
}

.breadcrumb_urgency {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  padding-left: 35px;
  box-sizing: border-box;
}

.breadcrumb_urgency .icon {
  padding: initial;
  box-sizing: border-box;
}

.breadcrumb_urgency {
  background-color: #eef7f9;
  color: #9cbdc5;
}

.description {
  color: #8eb2bb;
  padding: 4rem 2rem 1rem 3rem !important;
}

.description-ticket {
  color: #8eb2bb;
  padding: 2rem 3rem 3rem 3rem !important;
}

.description-ticket p {
  line-height: 1.5rem;
  padding: 1rem 0;
}

.description-ticket .title {
  color: #04ab9d;
  font-size: 1.2rem;
  font-weight: bold;
}

.description p {
  line-height: 1.5rem;
  padding: 1rem 0;
}

.description .title {
  color: #00aa9b;
  font-size: 18px;
  font-family: "Open Sans", Helvetica, Arial, serif;
  line-height: 24px;
  font-weight: 600;
}

.col .card-num-docs {
  padding-left: 8px;
  padding-top: 3px;
  background: #7fa7b2;
  color: #fff;
  width: 25px !important;
  height: 25px !important;
  border-radius: 50%;
  display: inline-block;
}
</style>
